<template>
  <div class="resume-contest-submit">
    <div class="contest-head">
      <div class="head-left">
        <el-image
          class="head-icon"
          :src="require(`@/assets/image/recommend.png`)"
          fit="contain"
        ></el-image>
        <div class="head-title">AI简历</div>
      </div>
      <el-image
        class="head-right"
        @click="sendExit"
        :src="require(`@/assets/image/exit2.png`)"
        fit="contain"
      ></el-image>
    </div>
    <div class="contest-main">
      <el-row :gutter="24">
        <el-col :span="16">
          <div class="flow-l">
            <div class="col-center">
              <div class="main-top" @click="personalDetail('personal_details')">
                <div class="personal-data">
                  <div class="data-name">
                    {{ main_data.personal_details.name }}
                  </div>
                  <div class="data-position">
                    求职意向：{{ main_data.personal_details.position }}
                  </div>
                  <div class="grade-location">
                    <span class="data"
                      >学历：{{ main_data.personal_details.grade }}</span
                    >
                    <span>地址：{{ main_data.personal_details.location }}</span>
                  </div>
                  <div class="phone-email">
                    <span class="data"
                      >电话：{{ main_data.personal_details.phone }}</span
                    >
                    <span>邮箱：{{ main_data.personal_details.email }}</span>
                  </div>
                </div>
                <div class="personal-img">
                  <el-image
                    class="img"
                    :src="main_data.personal_details.img"
                    fit="cover"
                  ></el-image>
                </div>
              </div>
              <div class="main-bottom">
                <div class="main-data">
                  <div class="education-img">
                    <span class="title">教育背景</span>
                  </div>
                  <div
                    class="education-span"
                    @click="personalDetail('education_data', index)"
                    v-for="(item, index) in main_data.education_data"
                    :key="item.id"
                  >
                    <span>{{ item.college }}</span>
                    <span>{{ item.start_time }} - {{ item.end_time }}</span>
                    <span>{{ item.specialty }}</span>
                    <span>{{ item.education }}</span>
                    <span>{{ item.institute }}</span>
                    <el-image
                      class="remove-video"
                      @click="removeVideo(1, item)"
                      v-if="index !== 0"
                      :src="require(`@/assets/image/del.png`)"
                      fit="contain"
                    ></el-image>
                  </div>
                  <div class="form-compo-title-img" @click="addVideo(1)">
                    <div class="insert">新增</div>
                    <el-image
                      class="img"
                      :src="require(`@/assets/image/add.png`)"
                    ></el-image>
                  </div>
                </div>
                <div class="main-data">
                  <div class="education-img">
                    <span class="title">工作经历</span>
                  </div>
                  <div
                    class="item-data"
                    @click="personalDetail('work_data', index)"
                    v-for="(item, index) in main_data.work_data"
                    :key="item.id"
                  >
                    <div class="data-title">
                      <div>
                        <span class="title-left">{{ item.work_title }}</span>
                        <span>{{ item.start_time }} - {{ item.end_time }}</span>
                      </div>
                      <el-image
                        @click="removeVideo(2, item)"
                        v-if="index !== 0"
                        :src="require(`@/assets/image/del.png`)"
                        fit="contain"
                      ></el-image>
                    </div>
                    <div class="data-content">
                      <span class="content">{{ item.work_experience }}</span>
                    </div>
                  </div>
                  <div class="form-compo-title-img" @click="addVideo(2)">
                    <div class="insert">新增</div>
                    <el-image
                      class="img"
                      :src="require(`@/assets/image/add.png`)"
                    ></el-image>
                  </div>
                </div>
                <div class="main-data">
                  <div class="education-img">
                    <span class="title">项目经验</span>
                  </div>
                  <div
                    class="item-data"
                    @click="personalDetail('project_data', index)"
                    v-for="(item, index) in main_data.project_data"
                    :key="item.id"
                  >
                    <div class="data-title">
                      <div>
                        <span class="title-left">{{ item.project_title }}</span>
                        <span>{{ item.start_time }} - {{ item.end_time }}</span>
                      </div>
                      <el-image
                        @click="removeVideo(3, item)"
                        v-if="index !== 0"
                        :src="require(`@/assets/image/del.png`)"
                        fit="contain"
                      ></el-image>
                    </div>
                    <div class="data-content">
                      <span class="content">{{ item.project_experience }}</span>
                    </div>
                  </div>
                  <div class="form-compo-title-img" @click="addVideo(3)">
                    <div class="insert">新增</div>
                    <el-image
                      class="img"
                      :src="require(`@/assets/image/add.png`)"
                    ></el-image>
                  </div>
                </div>
                <div class="main-data" @click="personalDetail('skill_data')">
                  <div class="education-img">
                    <span class="title">技能优势</span>
                  </div>
                  <div class="item-data">
                    <div class="data-content">
                      <span class="content">{{ main_data.skill_data }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="col-right">
            <div class="right-top">
              <div class="top-progress">
                <el-progress
                  :show-text="false"
                  :stroke-width="14"
                  :width="168"
                  type="circle"
                  :percentage="progressCount"
                ></el-progress>
                <div class="progress-data">
                  <span class="progress-count">{{ progressCount }}</span>
                  <div class="progress-label">AI智能诊断</div>
                </div>
              </div>
              <div class="proposal">
                简历诊断优化建议：共<span class="proposal-count">{{
                  personalCount +
                  educationCount +
                  workCount +
                  projectCount +
                  skillCount
                }}</span
                >条
              </div>
              <div class="completeness">简历完成度{{ progressCount }}%</div>
            </div>
            <div class="right-bottom">
              <div class="bottom-data">
                <div
                  class="data-title"
                  @click="isPersonalCount = !isPersonalCount"
                >
                  <span>基本信息</span>
                  <div class="circle">{{ personalCount }}</div>
                </div>
                <div class="data-p" v-if="isPersonalCount">
                  <p
                    v-if="
                      main_data.personal_details.img ===
                      'https://bmbshopping.oss-cn-hangzhou.aliyuncs.com/shopgoods/0.1372555556030275f52fcef3161f505f12aa10574f3ce35.png'
                    "
                  >
                    提示：检测到未上传照片，建议上传一寸的免冠职业照。
                  </p>
                  <p v-if="main_data.personal_details.name === ''">
                    提示：检测到没有填写姓名字段，建议填写你的姓名。
                  </p>
                  <p v-if="main_data.personal_details.sex === ''">
                    提示：检测到没有填写性别字段，建议选择你的性别。
                  </p>
                  <p v-if="main_data.personal_details.phone === ''">
                    提示：检测到没有填写电话字段，建议填写你的电话号码。
                  </p>
                  <p v-if="main_data.personal_details.email === ''">
                    提示：检测到没有填写邮箱字段，建议完善邮箱信息。
                  </p>
                  <p v-if="main_data.personal_details.grade === ''">
                    提示：检测到没有填写学历字段，建议填写你的学历信息。
                  </p>
                  <p v-if="main_data.personal_details.location === ''">
                    提示：检测到没有填写地址字段，建议填写到你将要投简历的城市。
                  </p>
                  <p v-if="main_data.personal_details.position === ''">
                    提示：检测到没有填写求职意向字段，建议完善你的求职意向。
                  </p>
                  <p v-if="main_data.personal_details.job_salary === ''">
                    提示：检测到没有填写期望薪资，建议填写你的期望薪资。
                  </p>
                </div>
              </div>
              <div class="bottom-data">
                <div
                  class="data-title"
                  @click="isEducationCount = !isEducationCount"
                >
                  <span>教育背景</span>
                  <div class="circle">{{ educationCount }}</div>
                </div>
                <div class="data-p" v-if="isEducationCount">
                  <p v-if="main_data.education_data[0].college === ''">
                    提示：检测到没有填写学校字段，建议填写你的学校名称。
                  </p>
                  <p v-if="main_data.education_data[0].specialty === ''">
                    提示：检测到没有填写专业字段，建议填写你的所属专业名称。
                  </p>
                  <p v-if="main_data.education_data[0].education === ''">
                    提示：检测到没有填写学历字段，建议填写你的学历信息。
                  </p>
                  <p v-if="main_data.education_data[0].institute === ''">
                    提示：检测到没有填写学院字段，建议填写你的所属学院。
                  </p>
                  <p v-if="main_data.education_data[0].start_time === ''">
                    提示：检测到没有填写开始日期字段，建议填写你的入学日期和离校日期。
                  </p>
                  <p v-if="main_data.education_data[0].desc === ''">
                    提示：检测到没有填写学校经历字段，建议完善你的在校经历。
                  </p>
                </div>
              </div>
              <div class="bottom-data">
                <div class="data-title" @click="isWorkCount = !isWorkCount">
                  <span>工作经历</span>
                  <div class="circle">{{ workCount }}</div>
                </div>
                <div class="data-p" v-if="isWorkCount">
                  <p v-if="main_data.work_data[0].work_title === ''">
                    提示：检测到没有填写公司字段，建议填写你的公司名称。
                  </p>
                  <p v-if="main_data.work_data[0].position === ''">
                    提示：检测到没有填写职位字段，建议填写你在公司的职位。
                  </p>
                  <p v-if="main_data.work_data[0].start_time === ''">
                    提示：检测到没有填写开始日期字段，建议填写你的入职日期和离职日期。
                  </p>
                  <p v-if="main_data.work_data[0].work_experience === ''">
                    提示：检测到没有填写工作经历字段，建议完善你的工作经历。
                  </p>
                </div>
              </div>
              <div class="bottom-data">
                <div
                  class="data-title"
                  @click="isProjectCount = !isProjectCount"
                >
                  <span>项目经验</span>
                  <div class="circle">{{ projectCount }}</div>
                </div>
                <div class="data-p" v-if="isProjectCount">
                  <p v-if="main_data.project_data[0].project_title === ''">
                    提示：检测到没有填写名称字段，建议填写你的项目名称。
                  </p>
                  <p v-if="main_data.project_data[0].duty === ''">
                    提示：检测到没有填写职务字段，建议填写你在该项目中负责的职务。
                  </p>
                  <p v-if="main_data.project_data[0].start_time === ''">
                    提示：检测到没有填写开始日期字段，建议填写你的项目开始时间和结束时间。
                  </p>
                  <p v-if="main_data.project_data[0].project_experience === ''">
                    提示：检测到没有填写项目总结字段，建议完善你的项目总结。
                  </p>
                </div>
              </div>
              <div class="bottom-data">
                <div class="data-title" @click="isSkillCount = !isSkillCount">
                  <span>技能优势</span>
                  <div class="circle">{{ skillCount }}</div>
                </div>
                <div class="data-p" v-if="isSkillCount">
                  <p v-if="main_data.skill_data === ''">
                    提示：检测到没有填写技能优势字段，建议完善你的技能优势。
                  </p>
                </div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- 基本信息弹窗 -->
    <el-dialog title="基本信息" :visible.sync="personal_details_dialog">
      <el-form
        :model="main_data.personal_details"
        :rules="rules"
        ref="rulForm"
        class="myform"
      >
        <div class="form-top">
          <div class="top-left">
            <div class="form-compo">
              <div class="form-compo-div">
                <el-form-item label="姓名" prop="name">
                  <el-input
                    class="form-input"
                    size="small"
                    v-model="main_data.personal_details.name"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="form-compo-div">
                <el-form-item label="性别" prop="sex">
                  <el-select
                    class="form-input"
                    size="small"
                    v-model="main_data.personal_details.sex"
                    placeholder="请选择性别"
                  >
                    <el-option label="男" value="1"></el-option>
                    <el-option label="女" value="2"></el-option>
                  </el-select>
                </el-form-item>
              </div>
            </div>
            <div class="form-compo">
              <div class="form-compo-div">
                <el-form-item label="电话" prop="phone">
                  <el-input
                    class="form-input"
                    size="small"
                    v-model="main_data.personal_details.phone"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="form-compo-div">
                <el-form-item label="邮箱" prop="email">
                  <el-input
                    class="form-input"
                    size="small"
                    v-model="main_data.personal_details.email"
                  ></el-input>
                </el-form-item>
              </div>
            </div>
            <div class="form-compo">
              <div class="form-compo-div">
                <el-form-item label="学历" prop="grade">
                  <el-select
                    v-model="main_data.personal_details.grade"
                    class="form-input"
                    filterable
                    size="small"
                    placeholder="请选择"
                  >
                    <el-option label="初中" value="初中"></el-option>
                    <el-option label="高中" value="高中"></el-option>
                    <el-option label="中职" value="中职"></el-option>
                    <el-option label="大专" value="大专"></el-option>
                    <el-option label="本科" value="本科"></el-option>
                    <el-option label="研究生" value="研究生"></el-option>
                    <el-option label="博士生" value="博士生"></el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="form-compo-div">
                <el-form-item label="地址" prop="location">
                  <el-input
                    class="form-input"
                    size="small"
                    v-model="main_data.personal_details.location"
                  ></el-input>
                </el-form-item>
              </div>
            </div>
            <div class="form-compo">
              <div class="form-compo-div">
                <el-form-item label="求职意向" prop="position">
                  <el-input
                    class="form-four"
                    size="small"
                    v-model="main_data.personal_details.position"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="form-compo-div">
                <el-form-item label="期望薪资" prop="job_salary">
                  <el-input
                    class="form-four"
                    size="small"
                    placeholder="如：6000-8000"
                    v-model="main_data.personal_details.job_salary"
                  ></el-input>
                </el-form-item>
              </div>
            </div>
            <div class="form-pic-desc">
              <el-form-item>
                <el-image
                  class="pic-img"
                  :src="main_data.personal_details.img"
                ></el-image>
              </el-form-item>
              <div class="avatar-uploader">
                <i
                  class="el-icon-plus avatar-uploader-icon"
                  @click="addPic"
                ></i>
              </div>
            </div>
          </div>
          <div class="top-right">
            <div class="right-title">填写帮助</div>
            <div>
              <p>1、红色*标注的为必填信息，请完善；</p>
              <p>2、手机号需要填写11位的有效号码，将用于单位HR联系；</p>
              <p>
                3、邮箱填写时请注意您的输入法是否是纯英文状态，请注意半角全角的切换（只能用半角）
              </p>
            </div>
          </div>
        </div>
        <div class="form-bottom">
          <el-button type="primary" size="mini" @click="insertSubmitApi(1)"
            >保 存</el-button
          >
          <el-button size="mini" @click="personal_details_dialog = false"
            >取 消</el-button
          >
        </div>
      </el-form>
    </el-dialog>
    <!-- 上传图片弹窗 -->
    <MyDialog
      :dialogVisible="dialogVisible"
      @changeDialog="changeDialog"
      @getPic="getPic"
      :limit="limit"
    />
    <!-- 教育背景弹窗 -->
    <el-dialog title="教育背景" :visible.sync="education_dialog">
      <el-form
        :model="selectedChat"
        :rules="education_rules"
        ref="education_rulForm"
        class="myform"
      >
        <div class="form-top">
          <div class="top-left">
            <div class="form-compo">
              <div class="form-compo-div">
                <el-form-item label="学校" prop="college">
                  <el-input
                    class="form-input"
                    size="small"
                    v-model="selectedChat.college"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="form-compo-div">
                <el-form-item label="专业" prop="specialty">
                  <el-input
                    class="form-input"
                    size="small"
                    v-model="selectedChat.specialty"
                  ></el-input>
                </el-form-item>
              </div>
            </div>
            <div class="form-compo">
              <div class="form-compo-div">
                <el-form-item label="学历" prop="education">
                  <el-input
                    class="form-input"
                    size="small"
                    v-model="selectedChat.education"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="form-compo-div">
                <el-form-item label="学院" prop="institute">
                  <el-input
                    class="form-input"
                    size="small"
                    v-model="selectedChat.institute"
                  ></el-input>
                </el-form-item>
              </div>
            </div>
            <el-form-item label="开始日期" prop="start_time">
              <el-date-picker
                style="width: 38%"
                type="date"
                placeholder="开始日期"
                v-model="selectedChat.start_time"
                size="small"
                format="yyyy 年 MM 月"
                value-format="yyyy-MM"
              ></el-date-picker>
              <span>-</span>
              <el-date-picker
                style="width: 38%"
                type="date"
                placeholder="结束日期"
                v-model="selectedChat.end_time"
                size="small"
                format="yyyy 年 MM 月"
                value-format="yyyy-MM"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="学校经历">
              <el-input
                type="textarea"
                v-model="selectedChat.desc"
                size="small"
              ></el-input>
            </el-form-item>

            <div class="ai-analysis">
              <div class="analysis-top">
                <el-image
                  class="top-image"
                  :src="require(`@/assets/image/logomini.png`)"
                ></el-image>
                <div class="top-content">
                  <span
                    >hi！你好，我是你的简历助理。我可以根据你填写的内容生成或者优化简历内容。</span
                  >
                  <el-button
                    type="primary"
                    size="mini"
                    @click="aiAnalysis(1, selectedChat.desc)"
                    >生 成</el-button
                  >
                </div>
              </div>
              <el-image
                v-if="analysisContent === '......'"
                class="answer-img"
                :src="require(`@/assets/image/loading.gif`)"
                fit="fit"
              ></el-image>
              <div v-else class="analysis-content">{{ analysisContent }}</div>
            </div>
          </div>
          <div class="top-right">
            <div class="right-title">填写帮助</div>
            <div>
              <p>1、请完善学校名称、起止日期、学历、专业名称等信息；</p>
              <p>
                2、经历描述尽量简介，突出重点。成绩优异可以加上GPA，年级排名等信息，不超过50个字；
              </p>
            </div>
          </div>
        </div>
        <div class="form-bottom">
          <el-button type="primary" size="mini" @click="insertSubmitApi(2)"
            >保 存</el-button
          >
          <el-button size="mini" @click="education_dialog = false"
            >取 消</el-button
          >
        </div>
      </el-form>
    </el-dialog>
    <!-- 工作经历弹窗 -->
    <el-dialog title="工作经历" :visible.sync="work_dialog">
      <el-form
        :model="selectedChat"
        :rules="work_rules"
        ref="work_rulForm"
        class="myform"
      >
        <div class="form-top">
          <div class="top-left">
            <div class="form-compo">
              <div class="form-compo-div">
                <el-form-item label="公司" prop="work_title">
                  <el-input
                    class="form-input"
                    size="small"
                    v-model="selectedChat.work_title"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="form-compo-div">
                <el-form-item label="职位" prop="position">
                  <el-input
                    class="form-input"
                    size="small"
                    v-model="selectedChat.position"
                  ></el-input>
                </el-form-item>
              </div>
            </div>
            <el-form-item label="开始日期" prop="start_time">
              <el-date-picker
                style="width: 38%"
                type="date"
                placeholder="开始日期"
                v-model="selectedChat.start_time"
                size="small"
                format="yyyy 年 MM 月"
                value-format="yyyy-MM"
              ></el-date-picker>
              <span>-</span>
              <el-date-picker
                style="width: 38%"
                type="date"
                placeholder="结束日期"
                v-model="selectedChat.end_time"
                size="small"
                format="yyyy 年 MM 月"
                value-format="yyyy-MM"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="工作经历">
              <el-input
                type="textarea"
                v-model="selectedChat.work_experience"
                size="small"
              ></el-input>
            </el-form-item>
            <div class="ai-analysis">
              <div class="analysis-top">
                <el-image
                  class="top-image"
                  :src="require(`@/assets/image/logomini.png`)"
                ></el-image>
                <div class="top-content">
                  <span
                    >hi！你好，我是你的简历助理。我可以根据你填写的内容生成或者优化简历内容。</span
                  >
                  <el-button
                    type="primary"
                    size="mini"
                    @click="aiAnalysis(2, selectedChat.work_experience)"
                    >生 成</el-button
                  >
                </div>
              </div>
              <el-image
                v-if="analysisContent === '......'"
                class="answer-img"
                :src="require(`@/assets/image/loading.gif`)"
                fit="fit"
              ></el-image>
              <div v-else class="analysis-content">{{ analysisContent }}</div>
            </div>
          </div>
          <div class="top-right">
            <div class="right-title">填写帮助</div>
            <div>
              <p>
                1、工作/实习经历的描写请尽量与申请的工作内容相匹配，侧重描述自己负责工作；
              </p>
              <p>2、工作/实习经历应采用倒叙形式，突出最近的经历；</p>
              <p>
                3、合理使用数字来描述数量，质量，效率和业绩等，突出个人成果以及做出的贡献；
              </p>
            </div>
          </div>
        </div>
        <div class="form-bottom">
          <el-button type="primary" size="mini" @click="insertSubmitApi(3)"
            >保 存</el-button
          >
          <el-button size="mini" @click="work_dialog = false">取 消</el-button>
        </div>
      </el-form>
    </el-dialog>
    <!-- 项目经验弹窗 -->
    <el-dialog title="项目经验" :visible.sync="project_dialog">
      <el-form
        :model="selectedChat"
        :rules="project_rules"
        ref="project_rulForm"
        class="myform"
      >
        <div class="form-top">
          <div class="top-left">
            <div class="form-compo">
              <div class="form-compo-div">
                <el-form-item label="名称" prop="project_title">
                  <el-input
                    class="form-input"
                    size="small"
                    v-model="selectedChat.project_title"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="form-compo-div">
                <el-form-item label="职务" prop="duty">
                  <el-input
                    class="form-input"
                    size="small"
                    v-model="selectedChat.duty"
                  ></el-input>
                </el-form-item>
              </div>
            </div>
            <el-form-item label="开始日期" prop="start_time">
              <el-date-picker
                style="width: 38%"
                type="date"
                placeholder="开始日期"
                v-model="selectedChat.start_time"
                size="small"
                format="yyyy 年 MM 月"
                value-format="yyyy-MM"
              ></el-date-picker>
              <span>-</span>
              <el-date-picker
                style="width: 38%"
                type="date"
                placeholder="结束日期"
                v-model="selectedChat.end_time"
                size="small"
                format="yyyy 年 MM 月"
                value-format="yyyy-MM"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="项目总结" prop="project_experience">
              <el-input
                type="textarea"
                v-model="selectedChat.project_experience"
                size="small"
              ></el-input>
            </el-form-item>
            <div class="ai-analysis">
              <div class="analysis-top">
                <el-image
                  class="top-image"
                  :src="require(`@/assets/image/logomini.png`)"
                ></el-image>
                <div class="top-content">
                  <span
                    >hi！你好，我是你的简历助理。我可以根据你填写的内容生成或者优化简历内容。</span
                  >
                  <el-button
                    type="primary"
                    size="mini"
                    @click="aiAnalysis(3, selectedChat.project_experience)"
                    >生 成</el-button
                  >
                </div>
              </div>
              <el-image
                v-if="analysisContent === '......'"
                class="answer-img"
                :src="require(`@/assets/image/loading.gif`)"
                fit="fit"
              ></el-image>
              <div v-else class="analysis-content">{{ analysisContent }}</div>
            </div>
          </div>
          <div class="top-right">
            <div class="right-title">填写帮助</div>
            <div>
              <p>1、填写项目名称、项目时间、项目职务、项目描述；</p>
              <p>
                2、与目标申请岗位相结合，主要任务具体化和量化，最后突出你的成果；
              </p>
            </div>
          </div>
        </div>
        <div class="form-bottom">
          <el-button type="primary" size="mini" @click="insertSubmitApi(4)"
            >保 存</el-button
          >
          <el-button size="mini" @click="project_dialog = false"
            >取 消</el-button
          >
        </div>
      </el-form>
    </el-dialog>
    <!-- 技能优势 -->
    <el-dialog title="技能优势" :visible.sync="skill_dialog">
      <el-form
        :model="main_data"
        :rules="skill_rules"
        ref="skill_rulForm"
        class="myform"
      >
        <div class="form-top">
          <div class="top-left">
            <el-form-item label="技能优势" prop="skill_data">
              <el-input
                type="textarea"
                v-model="main_data.skill_data"
                size="small"
              ></el-input>
            </el-form-item>
            <div class="ai-analysis">
              <div class="analysis-top">
                <el-image
                  class="top-image"
                  :src="require(`@/assets/image/logomini.png`)"
                ></el-image>
                <div class="top-content">
                  <span
                    >hi！你好，我是你的简历助理。我可以根据你填写的内容生成或者优化简历内容。</span
                  >
                  <el-button
                    type="primary"
                    size="mini"
                    @click="aiAnalysis(4, main_data.skill_data)"
                    >生 成</el-button
                  >
                </div>
              </div>
              <el-image
                v-if="analysisContent === '......'"
                class="answer-img"
                :src="require(`@/assets/image/loading.gif`)"
                fit="fit"
              ></el-image>
              <div v-else class="analysis-content">{{ analysisContent }}</div>
            </div>
          </div>
          <div class="top-right">
            <div class="right-title">填写帮助</div>
            <div>
              <p>1、完善技能优势；</p>
            </div>
          </div>
        </div>
        <div class="form-bottom">
          <el-button type="primary" size="mini" @click="insertSubmitApi(5)"
            >保 存</el-button
          >
          <el-button size="mini" @click="skill_dialog = false">取 消</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import MyDialog from "@/components/MyDialog.vue";
import { insertSubmit, getSubmit, getAnalysis } from "@/request/api";

export default {
  components: {
    MyDialog,
  },
  data() {
    return {
      main_data: {
        personal_details: {
          name: "",
          position: "",
          grade: "",
          location: "",
          phone: "",
          email: "",
          img: "https://bmbshopping.oss-cn-hangzhou.aliyuncs.com/shopgoods/0.1372555556030275f52fcef3161f505f12aa10574f3ce35.png",
          sex: "",
          job_salary: "",
        },
        education_data: [
          {
            id: 1,
            college: "",
            start_time: "",
            end_time: "",
            specialty: "",
            education: "",
            institute: "",
            desc: "",
          },
        ],
        work_data: [
          {
            id: 1,
            work_title: "",
            position: "",
            start_time: "",
            end_time: "",
            work_experience: "",
          },
        ],
        project_data: [
          {
            id: 1,
            project_title: "",
            duty: "",
            start_time: "",
            end_time: "",
            project_experience: "",
          },
        ],
        skill_data: "",
      },
      selectedChatData: null,
      selectedChatIndex: 0,
      analysisContent: "",
      // 基本信息
      personal_details_dialog: false,
      rules: {
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        sex: [{ required: true, message: "请选择性别", trigger: "blur" }],
        phone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            pattern: /^1[3-9]\d{9}$/,
            message: "请输入有效的手机号码",
            trigger: "blur",
          },
        ],
        email: [{ required: true, message: "请输入邮箱", trigger: "blur" }],
        grade: [{ required: true, message: "请输入学历", trigger: "blur" }],
        location: [{ required: true, message: "请输入地址", trigger: "blur" }],
        position: [
          { required: true, message: "请输入求职意向", trigger: "blur" },
        ],
        job_salary: [
          { required: true, message: "请输入期望薪资", trigger: "blur" },
        ],
      },
      limit: 0,
      dialogVisible: false,
      // 教育背景
      education_dialog: false,
      education_rules: {
        college: [
          { required: true, message: "请输入学校名称", trigger: "blur" },
        ],
        specialty: [{ required: true, message: "请输入专业", trigger: "blur" }],
        education: [{ required: true, message: "请输入学历", trigger: "blur" }],
        institute: [{ required: true, message: "请输入学院", trigger: "blur" }],
        start_time: [
          { required: true, message: "请选择日期时间", trigger: "blur" },
        ],
      },
      // 工作经历
      work_dialog: false,
      work_rules: {
        work_title: [
          { required: true, message: "请输入公司名称", trigger: "blur" },
        ],
        position: [
          { required: true, message: "请输入你的职位", trigger: "blur" },
        ],
        start_time: [
          { required: true, message: "请选择日期时间", trigger: "blur" },
        ],
      },
      // 项目经验
      project_dialog: false,
      project_rules: {
        project_title: [
          { required: true, message: "请输入项目名称", trigger: "blur" },
        ],
        duty: [{ required: true, message: "请输入你的职务", trigger: "blur" }],
        start_time: [
          { required: true, message: "请选择日期时间", trigger: "blur" },
        ],
        project_experience: [
          { required: true, message: "请输入项目总结", trigger: "blur" },
        ],
      },
      // 技能优势
      skill_dialog: false,
      skill_rules: {
        skill_data: [
          { required: true, message: "请输入技能优势", trigger: "blur" },
        ],
      },
      // 简历完成度
      isPersonalCount: false,
      isEducationCount: false,
      isWorkCount: false,
      isProjectCount: false,
      isSkillCount: false,
    };
  },
  computed: {
    selectedChat() {
      return this.selectedChatData === null
        ? {}
        : this.main_data[this.selectedChatData][this.selectedChatIndex];
    },
    progressCount() {
      return Math.trunc(
        ((24 -
          (this.personalCount +
            this.educationCount +
            this.workCount +
            this.projectCount +
            this.skillCount)) /
          24) *
          100
      );
    },
    personalCount() {
      let count = 0;
      if (
        this.main_data.personal_details.img ===
        "https://bmbshopping.oss-cn-hangzhou.aliyuncs.com/shopgoods/0.1372555556030275f52fcef3161f505f12aa10574f3ce35.png"
      )
        count++;
      if (this.main_data.personal_details.name === "") count++;
      if (this.main_data.personal_details.sex === "") count++;
      if (this.main_data.personal_details.phone === "") count++;
      if (this.main_data.personal_details.email === "") count++;
      if (this.main_data.personal_details.grade === "") count++;
      if (this.main_data.personal_details.location === "") count++;
      if (this.main_data.personal_details.position === "") count++;
      if (this.main_data.personal_details.job_salary === "") count++;
      return count;
    },
    educationCount() {
      let count = 0;
      if (this.main_data.education_data[0].college === "") count++;
      if (this.main_data.education_data[0].specialty === "") count++;
      if (this.main_data.education_data[0].education === "") count++;
      if (this.main_data.education_data[0].institute === "") count++;
      if (this.main_data.education_data[0].start_time === "") count++;
      if (this.main_data.education_data[0].desc === "") count++;
      return count;
    },
    workCount() {
      let count = 0;
      if (this.main_data.work_data[0].work_title === "") count++;
      if (this.main_data.work_data[0].position === "") count++;
      if (this.main_data.work_data[0].start_time === "") count++;
      if (this.main_data.work_data[0].work_experience === "") count++;
      return count;
    },
    projectCount() {
      let count = 0;
      if (this.main_data.project_data[0].project_title === "") count++;
      if (this.main_data.project_data[0].duty === "") count++;
      if (this.main_data.project_data[0].start_time === "") count++;
      if (this.main_data.project_data[0].project_experience === "") count++;
      return count;
    },
    skillCount() {
      return this.main_data.skill_data === "" ? 1 : 0;
    },
  },
  methods: {
    sendExit() {
      this.$emit("update-value");
    },
    // 掉AI帮写接口
    async getAiAnalysis(postData) {
      this.analysisContent = "......";

      try {
        const res = await getAnalysis(postData);
        if (res.status !== 200 || res.data.code !== "1000") {
          this.$message.error(res.data.msg);
          return;
        }

        this.analysisContent = res.data.data;
      } catch (error) {
        this.$message.error(error);
      }
    },
    aiAnalysis(type, data) {
      try {
        switch (type) {
          case 1:
            if (data === "") {
              return this.$message.warning("请简单写一下学校经历");
            }
            this.getAiAnalysis({ type: type, data: data });

            break;
          case 2:
            if (data === "") {
              return this.$message.warning("请简单写一下工作经历");
            }
            this.getAiAnalysis({ type: type, data: data });

            break;
          case 3:
            if (data === "") {
              return this.$message.warning("请简单写一下项目总结");
            }
            this.getAiAnalysis({ type: type, data: data });

            break;
          case 4:
            if (data === "") {
              return this.$message.warning("请简单写一下技能优势");
            }
            this.getAiAnalysis({ type: type, data: data });

            break;
        }
      } catch (error) {
        this.$message.error("生成ai推荐失败");
      }
    },
    insertSubmitApi(dialog) {
      try {
        switch (dialog) {
          case 1:
            this.$refs.rulForm.validate((valid) => {
              if (valid) {
                this.submitApi(this.main_data);

                this.personal_details_dialog = false;
              } else {
                console.log("error submit!!");
                return false;
              }
            });
            break;
          case 2:
            this.$refs.education_rulForm.validate((valid) => {
              if (valid) {
                this.submitApi(this.main_data);

                this.education_dialog = false;
              } else {
                console.log("error submit!!");
                return false;
              }
            });
            break;
          case 3:
            this.$refs.work_rulForm.validate((valid) => {
              if (valid) {
                this.submitApi(this.main_data);

                this.work_dialog = false;
              } else {
                console.log("error submit!!");
                return false;
              }
            });
            break;
          case 4:
            this.$refs.project_rulForm.validate((valid) => {
              if (valid) {
                this.submitApi(this.main_data);

                this.project_dialog = false;
              } else {
                console.log("error submit!!");
                return false;
              }
            });
            break;
          case 5:
            this.$refs.skill_rulForm.validate((valid) => {
              if (valid) {
                this.submitApi(this.main_data);

                this.skill_dialog = false;
              } else {
                console.log("error submit!!");
                return false;
              }
            });
            break;
        }
      } catch (error) {
        this.$message.error("保存简历出错");
      }
    },
    // 新增分类
    addVideo(type) {
      switch (type) {
        case 1:
          this.main_data.education_data.push({
            id: this.main_data.education_data.length + 1,
            college: "",
            start_time: "",
            end_time: "",
            specialty: "",
            education: "",
            institute: "",
            desc: "",
          });
          break;
        case 2:
          this.main_data.work_data.push({
            id: this.main_data.work_data.length + 1,
            work_title: "",
            position: "",
            start_time: "",
            end_time: "",
            work_experience: "",
          });
          break;
        case 3:
          this.main_data.project_data.push({
            id: this.main_data.project_data.length + 1,
            project_title: "",
            duty: "",
            start_time: "",
            end_time: "",
            project_experience: "",
          });
          break;
      }
    },
    // 删除分类
    removeVideo(type, item) {
      event.stopPropagation();

      this.selectedChatData = null;
      this.selectedChatIndex = 0;
      let index = 0;
      switch (type) {
        case 1:
          index = this.main_data.education_data.indexOf(item);
          if (index !== -1) {
            this.main_data.education_data.splice(index, 1);
          }
          break;
        case 2:
          index = this.main_data.work_data.indexOf(item);
          if (index !== -1) {
            this.main_data.work_data.splice(index, 1);
          }
          break;
        case 3:
          index = this.main_data.project_data.indexOf(item);
          if (index !== -1) {
            this.main_data.project_data.splice(index, 1);
          }
          break;
      }
    },
    // 掉保存接口
    async submitApi(postData) {
      try {
        const res = await insertSubmit({ main_data: JSON.stringify(postData) });
        if (res.status !== 200 || res.data.code !== "1000") {
          this.$message.error(res.data.msg);
          return;
        }
      } catch (error) {
        this.$message.error("保存简历出错");
      }
    },
    addPic() {
      this.limit = 1;
      this.dialogVisible = true;
    },
    // 取消按钮，关闭上传图片弹窗
    changeDialog() {
      this.dialogVisible = false;
    },
    // 组件保存图片
    getPic(picArr, id) {
      this.dialogVisible = false;
      picArr.forEach((pic) => {
        if (id === 1) {
          this.main_data.personal_details.img = pic;
        }
      });
    },
    personalDetail(title, value = null) {
      this.analysisContent = "";

      switch (title) {
        case "personal_details":
          this.personal_details_dialog = true;
          break;
        case "education_data":
          this.selectedChatData = title;
          this.selectedChatIndex = value;
          this.education_dialog = true;
          break;
        case "work_data":
          this.selectedChatData = title;
          this.selectedChatIndex = value;
          this.work_dialog = true;
          break;
        case "project_data":
          this.selectedChatData = title;
          this.selectedChatIndex = value;
          this.project_dialog = true;
          break;
        case "skill_data":
          this.skill_dialog = true;
          break;
      }
    },
    async init() {
      try {
        const res = await getSubmit();
        if (res.status !== 200 || res.data.code !== "1000") {
          this.$message.error(res.data.msg);
          return;
        }

        if (res.data.data !== false) {
          this.main_data = JSON.parse(res.data.data.resume);
        }
      } catch (error) {
        this.$message.error("获取在线简历记录失败");
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="less" scoped>
.resume-contest-submit {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .myform {
    display: flex;
    flex-direction: column;
    .form-top {
      height: 400px;
      border-bottom: 1px solid #c1c1c1;
      display: flex;
      margin-bottom: 20px;
      .top-left {
        width: 70%;
        overflow-y: auto;
        .ai-analysis {
          border: 1px dashed #0c69f5;
          background-color: #fafcff;
          padding: 10px;
          display: flex;
          flex-direction: column;
          .analysis-top {
            display: flex;
            margin-bottom: 10px;
            align-items: center;
            .top-image {
              width: 60px;
              height: auto;
              flex-shrink: 0;
              flex-grow: 0;
              margin-right: 4px;
            }
          }
          .analysis-content {
            white-space: pre-wrap;
          }
        }
        .form-compo {
          display: flex;
          .form-compo-div {
            width: 100%;
            .form-input {
              width: 70%;
            }
            .form-four {
              width: 60%;
            }
          }
        }
        .form-pic-desc {
          display: flex;
          align-items: center;
          .avatar-uploader {
            border: 1px dashed #d9d9d9;
            border-radius: 6px;
            cursor: pointer;
            overflow: hidden;
            .avatar-uploader-icon {
              font-size: 28px;
              color: #8c939d;
              width: 120px;
              height: 120px;
              line-height: 120px;
              text-align: center;
            }
          }
          .avatar-uploader:hover {
            border-color: #409eff;
          }
          .pic-img {
            width: 120px;
            height: 120px;
            margin-right: 10px;
          }
        }
      }
      .top-right {
        flex: 1;
        .right-title {
          font-size: 16px;
          font-weight: bold;
          color: #1e9fff;
          border-bottom: 1px solid #c1c1c1;
          padding: 6px;
          margin-bottom: 10px;
        }
      }
    }
    .form-bottom {
      text-align: center;
    }
  }
  .contest-main {
    height: 92%;
    padding: 4px;
    .el-row {
      height: 100%;
      .el-col {
        height: 100%;
        .flow-l {
          height: 100%;
          overflow-y: auto;
          background-color: #fff;
          .col-center {
            height: 100%;
            display: flex;
            flex-direction: column;
            .main-top {
              cursor: pointer;
              min-height: 28%;
              display: flex;
              &:hover {
                background-color: #f8f8f8;
              }
              .personal-data {
                width: 76%;
                display: flex;
                flex-direction: column;
                padding: 30px 50px;
                box-sizing: border-box;
                .data-name {
                  font-size: 22px;
                  margin-bottom: 14px;
                  font-weight: bold;
                }
                .data-position {
                  margin-bottom: 10px;
                }
                .grade-location {
                  margin-bottom: 10px;
                  display: flex;
                  font-size: 14px;
                }
                .data {
                  width: 40%;
                }
                .phone-email {
                  display: flex;
                  font-size: 14px;
                }
              }
              .personal-img {
                flex: 1;
                display: flex;
                align-items: center;
                .img {
                  width: 140px;
                  height: 140px;
                  border-radius: 50%;
                  border: 2px solid #dfdfdf;
                }
              }
            }
            .main-bottom {
              flex: 1;
              padding: 28px;
              box-sizing: border-box;
              .main-data {
                display: flex;
                flex-direction: column;
                margin-bottom: 12px;
                .form-compo-title-img {
                  border: 1px solid #0a82fa;
                  border-radius: 6px;
                  width: 60px;
                  height: 24px;
                  justify-content: center;
                  display: flex;
                  color: #54aaff;
                  cursor: pointer;
                  font-size: 14px;
                  align-items: center;
                  .insert {
                    margin-right: 4px;
                  }
                  .img {
                    width: 16px;
                    height: 16px;
                  }
                }
                .education-img {
                  height: 60px;
                  font-size: 20px;
                  display: flex;
                  align-items: center;
                  .title {
                    width: 100px;
                    text-align: center;
                    border-left: 5px solid #2a6ee9;
                  }
                }
                .item-data {
                  display: flex;
                  flex-direction: column;
                  margin-bottom: 10px;
                  cursor: pointer;
                  &:hover {
                    background-color: #f8f8f8;
                  }
                  .data-title {
                    display: flex;
                    font-weight: bold;
                    margin-bottom: 4px;
                    justify-content: space-between;
                    .title-left {
                      margin-right: 10px;
                    }
                  }
                  .data-content {
                    padding: 4px;
                    font-size: 14px;
                    .content {
                      white-space: pre-wrap;
                    }
                  }
                }
                .education-span {
                  display: flex;
                  font-size: 14px;
                  justify-content: space-between;
                  font-weight: bold;
                  height: 50px;
                  align-items: center;
                  cursor: pointer;
                  .remove-video {
                    height: 20px;
                  }
                  &:hover {
                    background-color: #f8f8f8;
                  }
                }
              }
            }
          }
        }
        .col-right {
          background-color: #fff;
          height: 100%;
          overflow-y: auto;
          .right-top {
            height: 330px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .proposal {
              font-size: 18px;
              margin-bottom: 10px;
              .proposal-count {
                color: #f29a0f;
              }
            }
            .completeness {
              background-color: #2a6ee9;
              color: #fff;
              font-size: 22px;
              font-weight: bold;
              width: 80%;
              height: 44px;
              line-height: 44px;
              text-align: center;
              border-radius: 24px;
            }
            .top-progress {
              position: relative;
              .progress-data {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -54%);
                display: flex;
                flex-direction: column;
                align-items: center;
                .progress-count {
                  color: #2a6ee9;
                  font-size: 58px;
                  font-weight: bold;
                }
                .progress-label {
                  background-color: #f29a0f;
                  color: #fff;
                  padding: 4px 6px;
                  font-weight: bold;
                  border-radius: 6px;
                  font-size: 14px;
                }
              }
            }
          }
          .right-bottom {
            display: flex;
            flex-direction: column;
            align-items: center;
            .bottom-data {
              display: flex;
              flex-direction: column;
              width: 84%;
              margin-bottom: 20px;
              .data-title {
                cursor: pointer;
                display: flex;
                justify-content: space-between;
                color: #9c9999;
                border: 1px solid #9c9999;
                border-radius: 8px;
                height: 44px;
                line-height: 44px;
                align-items: center;
                padding: 0 10px;
                .circle {
                  background-color: #f29a0f;
                  color: #fff;
                  width: 30px;
                  height: 30px;
                  border-radius: 100%;
                  line-height: 30px;
                  text-align: center;
                }
              }
              .data-p {
                font-size: 14px;
                color: #f29a0f;
              }
            }
          }
        }
      }
    }
  }
  .contest-head {
    height: 8%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .head-left {
      display: flex;
      align-items: center;
      .head-icon {
        width: 40px;
        margin-right: 6px;
      }
      .head-title {
        font-size: 14px;
        color: #7a7c7e;
      }
    }
    .head-right {
      padding-right: 1%;
      width: 70px;
      cursor: pointer;
    }
  }
}
</style>
